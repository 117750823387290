import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { IntlContextProvider } from "gatsby-plugin-intl"
import { ThemeProvider } from "styled-components"

import Footer from "src/developers/Footer"
import "../static/layout.css"
import AcceptCookieForm from "src/components/AcceptCookieForm"
import TopBanner from "src/common/TopBanner"
import Header from "src/developers/Header"
import useContent from "src/developers/useContent"
import { Indent } from "src/uikit"
import ModalsContainer from "./components/ModalsContainer"
import SEO from "./seo"
import themes from "src/developers/theme.json"

const GlobalStyles = createGlobalStyle`
  body {
    -webkit-font-smoothing: subpixel-antialiased;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Layout = ({ children, location, title, description, keywords }) => {
  const content = useContent()

  return (
    <IntlContextProvider>
      <ThemeProvider theme={themes.light}>
        <Wrap id="layout">
          <AcceptCookieForm />
          <SEO
            title={title}
            description={description}
            keywords={keywords}
            location={location}
          />
          <GlobalStyles />
          <TopBanner />
          <Header content={content} />
          <Indent lg={80} md={80} sm={80} />
          {children}
          <Footer content={content.footer} />
          <ModalsContainer location={location} />
        </Wrap>
      </ThemeProvider>
    </IntlContextProvider>
  )
}

Layout.defaultProps = {
  headerColor: {
    primaryColor: "#fff",
    secondaryColor: "#fff",
  },
}

export default Layout
